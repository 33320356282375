/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { LocationPermissions } from './location-permissions.model';

export interface UserLocationPermission {
  locationId: number;
  locationName: string;
  permissions: LocationPermissions;
}

export enum UserPermissions {
  CanManageOrganization = 1,
  CanManageUsers = 2,
  CanManageFormulary = 4,
  CanManageAdm = 8,
  CanManageAuth = 16,
  CanAccessLogs = 32,
}
